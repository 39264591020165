import React from 'react'
import { inject, observer } from 'mobx-react'

import { OverlayCloseCross } from 'components/assets/OverlayCloseCross'
import { headerDefaultStyle } from '../lib/styles'
import { HEADER_HEIGHT, BIG_PHONE_WIDHT } from '../lib/constants'

export const DiscoverButton = props => (
  <div className={`pa2 bg-white gray ${props.className}`}>
    {props.lang === 'en' ? 'Discover the topics' : 'Scopri i temi'}
  </div>
)

@inject('globalState')
@observer
export class Header extends React.Component {
  handleClick = () => {
    const { toggleOverlay } = this.props.globalState
    toggleOverlay('contents')
  }

  render() {
    const { width, overlayOpen, lang } = this.props.globalState
    return (
      <div
        style={{
          ...headerDefaultStyle,
          height: HEADER_HEIGHT,
          zIndex: 4,
        }}
        className="flex flex-row justify-between items-center"
      >
        <div className={`b pa2 ${width < BIG_PHONE_WIDHT ? 'f7' : 'f6'}`}>
          {'THE ROOM OF CHANGE'}
        </div>
        <div className={'pointer'} onClick={this.handleClick}>
          {overlayOpen ? (
            <OverlayCloseCross style={{ width: 15, height: 15, marginRight: '1rem' }} />
          ) : (
            <DiscoverButton className="f7 mr2" lang={lang} />
          )}
        </div>
      </div>
    )
  }
}
