import React from 'react'
import { Provider } from 'mobx-react'

import { Main } from 'components/Main'
import { globalState } from '../state/global'

export default class App extends React.Component {
  render() {
    return (
      <Provider globalState={globalState}>
        <Main />
      </Provider>
    )
  }
}
