export const contents = [
  {
    title: { text_en: '1) Nature', text_it: '1) Natura' },
    data: [
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '1.1 The disappearing Aral Sea',
          text_it: "1.1 La scomparsa del Lago d'Aral",
        },
        sections: [
          {
            svg: 'Asset2.svg',
            text_en: 'Height of the rectangle = depth of the main lake (m)',
            text_it: 'Altezza del rettangolo = profondità del lago principale (m)',
          },
          {
            svg: 'Asset3.svg',
            text_en:
              'Horizontal line, height from the top = depth of the smaller lake (m), after 1985',
            text_it:
              'Linea orizzontale, altezza dal margine superiore = profondità del lago secondario (m), dal 1985',
          },
          {
            svg: 'Asset4.svg',
            text_en: 'Vertical line, width from the left = surface of the main lake (km2)',
            text_it:
              'Linea verticale, larghezza dalla sinistra = superficie del lago principale (km2)',
          },
          {
            svg: 'Asset5.svg',
            text_en: 'Vertical line, width from the left = surface of the smaller lake (km2)',
            text_it:
              'Linea verticale, larghezza dalla sinistra = superficie del lago secondario (Km2)',
          },
          {
            svg: 'Asset6.svg',
            type: 'circle',
            text_en: 'Dot, height from the bottom = yearly water evaporation (km3)',
            text_it: 'Punto, altezza dal basso = evaporazione annua di acqua (km3)',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '1.2 Human impact on environment/landscape',
          text_it: "1.2 L'impatto dell'uomo sull'ambiente",
        },

        sections: [
          {
            svg: 'Asset2.svg',
            text_en: 'Pair of lines, distance from left = cumulative amount of global plastic',
            text_it:
              'Coppia di linee, distanza dalla sinistra = quantità globale di plastica accumulata',
          },
          {
            svg: 'Asset3.svg',
            text_en: 'Texture, relative height = % of recycled plastic',
            text_it: 'Texture, altezza relativa = % di plastica riciclata',
          },
          {
            svg: 'Asset4.svg',
            text_en: 'Texture, relative height = % of incinerated plastic',
            text_it: 'Texture, altezza relativa = % di plastica incenerita',
          },
          {
            svg: 'Asset5.svg',
            text_en: 'Texture, relative height = % of wasted plastic',
            text_it: 'Texture, relativa altezza = % di plastica buttata',
          },
          {
            svg: 'Asset6.svg',
            text_en: 'Line, height from the bottom = % of world land used for agriculture',
            text_it: "Linea, altezza dal basso = % di terra globale utilizzata per l'agricoltura",
          },
          {
            svg: 'Asset7.svg',
            text_en: 'Line, height from the bottom = % of world land covered with forest',
            text_it: 'Linea, altezza dal basso = % di terra coperta da foreste',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '1.3 The effects of climate change',
          text_it: '1.3 Gli effetti del cambiamento climatico',
        },
        sections: [
          {
            svg: 'Asset7.svg',
            text_en: 'Texture, relative width = number of hurricanes happened that year',
            text_it: "Texture, relativa larghezza = numero di uragani registrati quell'anno",
            subsections: [
              {
                svg: 'Asset2.svg',
                text_en: 'East Pacific Ocean',
                text_it: 'Oceano Pacifico orientale',
              },
              { svg: 'Asset3.svg', text_en: 'Atlantic Ocean', text_it: 'Oceano Atlantico' },
              { svg: 'Asset4.svg', text_en: 'Indian Ocean', text_it: 'Oceano Indiano' },
              {
                svg: 'Asset5.svg',
                text_en: 'West Pacific Ocean',
                text_it: 'Oceano Pacifico occidentale',
              },
            ],
          },
          {
            svg: 'Asset6.svg',
            text_en:
              'Line, height from the middle = combined land-surface, air and sea-surface water temperature anomalies',
            text_it:
              "Linea, altezza dalla metà = variazione della temperatura dell'aria, della superficie terrestre e della superficie dei mari",
          },
        ],
      },
    ],
  },
  {
    title: { text_en: '2) Universe', text_it: '2) Universo' },
    data: [
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '2.1 The future, as foretold in the past',
          text_it: '2.1 Il futuro come predizione del passato',
        },
        sections: [
          {
            svg: 'Asset11.svg',
            text_en: 'Presence of dot and line glyph = somebody that year predicted the apocalypse',
            text_it:
              "Presenza del punto e della linea = qualcuno quell'anno ha predetto un'apocalisse",
          },
          {
            svg: null,
            text_en: 'Survival to the supposed apocalypse:',
            text_it: "Sopravvissuto all'apocalisse predetta:",
            subsections: [
              {
                svg: 'Asset2.svg',
                text_en: 'the predictor died before',
                text_it: 'il predittore è deceduto prima',
              },
              {
                svg: 'Asset3.svg',
                text_en: 'the predictor died after',
                text_it: 'il predittore è deceduto dopo',
              },
            ],
          },
          {
            svg: null,
            text_en: 'Type of apocalyptic event:',
            text_it: 'Tipo di evento apocalittico:',
            subsections: [
              {
                svg: 'Asset4.svg',
                type: 'circle',
                text_en: 'technological',
                text_it: 'tecnologico',
              },
              { svg: 'Asset5.svg', type: 'circle', text_en: 'religious', text_it: 'religioso' },
              {
                svg: 'Asset6.svg',
                type: 'circle',
                text_en: 'natural disaster',
                text_it: 'disastro naturale',
              },
              { svg: 'Asset7.svg', type: 'circle', text_en: 'mystic', text_it: 'mistico' },
              { svg: 'Asset8.svg', type: 'circle', text_en: 'conspiracy', text_it: 'cospirazione' },
              {
                svg: 'Asset9.svg',
                type: 'circle',
                text_en: 'astronomical',
                text_it: 'astronomico',
              },
            ],
          },
          {
            svg: 'Asset12.svg',
            text_en: 'Pair of lines, width from the left = Google Ngram response for "apocalypse"',
            text_it:
              'Coppia di linee, larghezza dalla sinistra = Risposta Google Ngram per la parola "apocalypse"',
          },
          {
            svg: 'Asset10.svg',
            text_en: 'Pair of lines, width from the left = Google Ngram response for "future"',
            text_it:
              'Coppia di linee, larghezza dalla sinistra = Risposta Google Ngram per la parola "future"',
          },
          {
            svg: null,
            text_en:
              'When you enter phrases into the Google Books Ngram Viewer, it displays a graph showing how those phrases have occurred in a corpus of books over the selected years.',
            text_it:
              'Quando inserisci una parola in Google Books Ngram Viewer, il software produce un grafico che mostra quante volte quella appare nei libri pubblicati, durante il periodo di tempo selezionato.',
            format: 'italic',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '2.2 A record of lunar and solar eclipses',
          text_it: '2.2 La storia delle eclissi lunari e solari',
        },
        sections: [
          {
            svg: 'Asset2.svg',
            text_en: '1 line = 1 solar eclypse',
            text_it: '1 linea = 1 eclisse solare',
          },
          {
            svg: 'Asset3.svg',
            text_en: '1 line = 1 lunar eclypse',
            text_it: '1 linea = 1 eclisse lunare',
          },
          {
            svg: 'Asset4.svg',
            text_en: '1 dot = 1 of the eclypses was total',
            text_it: '1 punto = 1 delle eclissi è stata totale',
            type: 'circle',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '2.3 Universe discoveries and space exploration achievements',
          text_it: "2.3 Le scoperte dell'universo e l'esplorazione dello spazio",
        },
        sections: [
          {
            svg: 'Asset8.svg',
            text_en:
              'Presence of the pattern = citizen was the first of her/his nation to reach outer space',
            text_it:
              'Presenza del pattern = il cittadino è stato la/il prima/o della sua nazione a raggiungere lo spazio',
          },
          {
            trigger_overlay: true,
            type: 'geographical',
          },
          {
            svg: 'Asset9.svg',
            text_en:
              'Right end of the gradient = a new satellite was discovered in the Solar System',
            text_it:
              'Lato destro del gradiente = un nuovo satellite è stato scoperto nel sistema solare ',
          },
          {
            svg: null,
            text_en: 'Color of the gradient = a new planet was discovered in the Solar System',
            text_it: 'Colore del gradiente = un nuovo pianeta è stato scoperto nel sistema solare',
            subsections: [
              {
                svg: 'Asset2.svg',
                text_en: 'Mars',
                text_it: ' Marte',
              },
              {
                svg: 'Asset3.svg',
                text_en: 'Neptune',
                text_it: 'Nettuno',
              },
              {
                svg: 'Asset4.svg',
                text_en: 'Saturn',
                text_it: 'Saturno',
              },
              {
                svg: 'Asset5.svg',
                text_en: 'Uranus',
                text_it: 'Urano',
                funny: true,
              },
              {
                svg: 'Asset6.svg',
                text_en: 'Jupiter',
                text_it: 'Giove',
              },
              {
                svg: 'Asset7.svg',
                text_en: 'Dwarf planet',
                text_it: 'Pianeta nano',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: { text_en: '3) Animal Kingdom', text_it: '3) Regno Animale' },
    data: [
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '3.1 The extinctions of animal species in South America',
          text_it: "3.1 L'estinzione delle specie animali in Sud America",
        },
        sections: [
          {
            svg: 'Asset6.svg',
            text_en: 'Area of the pattern = reference for maximum population in South America',
            text_it: 'Area del pattern = riferimento per la popolazione massima in Sud America',
          },
          {
            svg: 'Asset7.svg',
            text_en: 'Area of the pattern = population in South America',
            text_it: 'Area del pattern = popolazione in Sud America',
          },
          {
            svg: 'Asset5.svg',
            text_en: 'Presence of a line = language became extinct in South America',
            text_it: 'Presenza della linea = una lingua si è estinta in Sud America',
          },
          {
            svg: 'Asset3.svg',
            type: 'circle',
            text_en: 'Presence of the dot = (a few) speakers of the language are still alive',
            text_it: 'Presenza del punto = (alcuni) parlanti della lingua sono ancora vivi',
          },
          {
            svg: 'Asset4.svg',
            text_en: 'Presence of the line = an animal species became extinct in South America',
            text_it: 'Presenza della linea = una specie animale si è estinta in Sud America',
          },
          {
            svg: 'Asset2.svg',
            text_en:
              'Position of the lines = nation in which the extinction happened, from the top:',
            text_it:
              "Posizione delle linee = nazione nella quale è avvenuta l'estinzione, dall'alto:",
          },
          {
            svg: null,
            type: 'list',
            text_en:
              'Argentina, Bolivia, Brazil, Chile, Colombia, Ecuador, Guyana, Perù, Uruguay, Venezuela',
            text_it:
              'Argentina, Bolivia, Brazil, Cile, Colombia, Ecuador, Guyana, Perù, Uruguay, Venezuela',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '3.2 The reducing world population of bees',
          text_it: '3.2 La diminuizione della popolazione mondiale di api ',
        },
        sections: [
          {
            svg: 'Asset2.svg',
            text_en: 'Thickness of the line = total amount of bee colonies in that continent',
            text_it: 'Spessore della linea = quantità totale di colonie di api in quel continente',
          },
          {
            trigger_overlay: true,
            type: 'geographical',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '3.3 The growing world population of cattles and chickens',
          text_it: "3.3 L'aumento della popolazione di bovini e polli",
        },
        sections: [
          {
            svg: 'Asset2.svg',
            text_en:
              'Vertical line, distance from the left = total amount of chickens in that continent',
            text_it:
              'Linea verticale, distanza dalla sinistra = quantità totale di polli in quel continente',
          },
          {
            svg: 'Asset3.svg',
            text_en:
              'Horizontal line, height from the bottom = total amount of cattles in that continent',
            text_it:
              'Linea orizzontale, altezza dal basso = quantità totale di bovini in quel continente',
          },
          {
            trigger_overlay: true,
            type: 'geographical',
          },
        ],
      },
    ],
  },
  {
    title: { text_en: '4) Society', text_it: '4) Società' },
    data: [
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '4.1 Achieving universal suffrage in countries around the world',
          text_it: '4.1 Il suffragio universale nei paesi del mondo',
        },
        sections: [
          {
            svg: 'Asset6.svg',
            text_en: 'Presence of the rectangle = a nation introduced:',
            text_it: 'Presenza del rettangolo = una nazione ha introdotto:',
            subsections: [
              {
                svg: 'Asset5.svg',
                text_en: 'Upper band = female suffrage',
                text_it: 'Barra superiore = suffragio femminile',
              },
              {
                svg: 'Asset4.svg',
                text_en: 'Upper-central band = universal suffrage',
                text_it: 'Barra superiore-centrale = suffragio universale',
              },
              {
                svg: 'Asset3.svg',
                text_en: 'Lower-central band = male suffrage',
                text_it: 'Barra inferiore-centrale = suffragio maschile',
              },
              {
                svg: 'Asset2.svg',
                text_en: 'Lower band = ethnic suffrage',
                text_it: 'Barra inferiore = suffragio etnico',
              },
            ],
          },
          {
            trigger_overlay: true,
            type: 'cultural',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '4.2 World population and its distribution',
          text_it: '4.2 La popolazione mondiale e la sua distribuzione',
        },
        sections: [
          {
            svg: 'Asset5.svg',
            text_en: 'Width of the line = total population of that continent',
            text_it: 'Larghezza della linea = popolazione totale in quel continente',
          },
          {
            trigger_overlay: true,
            type: 'cultural',
          },
          {
            svg: 'Asset4.svg',
            text_en: 'Relative position of the tick: =',
            text_it: 'Posizione della spunta: =',
            subsections: [
              {
                svg: 'Asset3.svg',
                text_en: 'rural population ratio',
                text_it: 'percentuale di popolazione rurale',
              },
              {
                svg: 'Asset4.svg',
                text_en: 'urban population ratio',
                text_it: 'percentuale di popolazione urbana',
              },
            ],
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '4.3 Forms of government and growing terrorism',
          text_it: '4.3 Forme di governo e crescita del terrorismo',
        },
        sections: [
          {
            svg: 'Asset8.svg',
            text_en: 'Height of the line = number of terrorist incidents in (from the left):',
            text_it: 'Altezza della linea = numero di incidenti terroristici in (dalla sinistra):',
          },
          {
            svg: null,
            type: 'list',
            text_en:
              'Africa, Northen Africa and Middle East, Asia, Europa, Latin America, North America, Oceania',
            text_it:
              'Africa, Nord Africa e Medio Oriente, Asia, Europa, America Latina, Nord America, Oceania',
          },
          {
            svg: 'Asset7.svg',
            text_en: 'Relative height of the patterns = ratio of people living in a:',
            text_it: 'Altezza del pattern = percentuale di persone che vive in una:',
            subsections: [
              { svg: 'Asset6.svg', text_en: 'Democracy', text_it: 'Democrazia' },
              { svg: 'Asset5.svg', text_en: 'Anocracy', text_it: 'Anocrazia' },
              { svg: 'Asset4.svg', text_en: 'Colony', text_it: 'Colonia' },
              { svg: 'Asset3.svg', text_en: 'Autocracy', text_it: 'Autocrazia' },
              {
                svg: 'Asset2.svg',
                text_en: 'Country in transition or no data',
                text_it: 'Situazione di transizione o mancanza di dati',
              },
            ],
          },
        ],
      },
    ],
  },

  {
    title: { text_en: '5) Hope', text_it: '5) Speranza' },
    data: [
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '5.1 The civil war and life expectancy in Cambodia',
          text_it: "5.1 La guerra civile e l'aspettativa di vita in Cambogia",
        },
        sections: [
          {
            svg: 'Asset2.svg',
            text_en:
              'Double line, height from the bottom = average life expectancy at birth, world',
            text_it:
              "Coppia di linee, altezza dal basso = media globale dell'aspettativa di vita al momento della nascita",
          },
          {
            svg: 'Asset3.svg',
            text_en:
              'Double line, height from the bottom = average life expectancy at birth, Cambodia',
            text_it:
              "Coppia di linee, altezza dal basso = media dell'aspettativa di vita alla nascita in Cambogia",
          },
          {
            svg: 'Asset5.svg',
            type: 'circle',
            text_en: 'Presence of the dot = a genocide was happening that year in Cambodia',
            text_it: "Presenza del punto = è avvenuto un genocidio in Cambogia quell'anno",
          },
          {
            svg: 'Asset6.svg',
            text_en: 'Presence of the line = a civil war was happening that year in Cambodia',
            text_it: "Presenza della linea = è avvenuta una guerra civile in Cambogia quell'anno",
          },
          {
            svg: 'Asset4.svg',
            text_en: 'Thickness of the line = total population in Cambodia',
            text_it: 'Spessore della linea = popolazione totale in Cambogia',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '5.2 A story of death',
          text_it: '5.2 Storia della morte',
        },
        sections: [
          {
            svg: 'Asset3.svg',
            text_en:
              'Diagonal line, height from the bottom = child mortality rate in that continent',
            text_it:
              'Linea diagonale, altezza dal basso = tasso di mortlità infantile in quel continente',
          },
          {
            trigger_overlay: true,
            type: 'cultural',
          },
          {
            svg: 'Asset2.svg',
            text_en:
              'Diagonal line, height from the bottom = yearly deaths in that continent, normalized by population',
            text_it:
              'Linea diagonale, altezza dal basso = morti annue in quel continente, normalizzate per popolazione',
          },
          {
            trigger_overlay: true,
            type: 'cultural',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '5.3 A story of birth',
          text_it: '5.3 Storia della nascita',
        },
        sections: [
          {
            svg: 'Asset2.svg',
            text_en:
              'Double line, height from the bottom = yearly births in that continent, normalized by population',
            text_it:
              'Coppia di linee, altezza dal basso = nascite annue in quel continente, normalizzate per popolazione',
          },
          {
            trigger_overlay: true,
            type: 'cultural',
          },
        ],
      },
    ],
  },
  {
    title: { text_en: '6) Happiness', text_it: '6) Felicità' },
    data: [
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '6.1 A story of depression in Uganda',
          text_it: '6.1 La storia della depressione in Uganda',
        },
        sections: [
          {
            svg: 'Asset8.svg',
            text_en:
              'Diagonal line, height from the bottom = Human Rights Protection Index in Uganda',
            text_it:
              'Lina diagonale, altezza dal basso = "Indice di Protezione dei Diritti Umani" in Uganda',
          },
          {
            svg: 'Asset7.svg',
            text_en: 'Group of lines, distance from the left = % of depressed people in the world',
            text_it: 'Gruppo di linee, distanza dalla sinistra = % di persone depresse nel mondo',
          },
          {
            svg: 'Asset6.svg',
            type: 'cricle',
            text_en: 'Group of lines, distance from the left = % of depressed people in Uganda',
            text_it: 'Gruppo di linee, distanza dalla sinistra = % di persone depresse in Uganda',
          },
          {
            svg: 'Asset5.svg',
            text_en: 'Tick, height from the bottom = Human Rights Protection Scores in Uganda',
            text_it:
              'Spunta, altezza dal basso = "Punteggio di Protezione dei Diritti Umani" in Uganda',
          },
          {
            svg: 'Asset4.svg',
            text_en: 'Group of lines, height from the bottom = Total Global Inequality Index',
            text_it: 'Gruppo di linee, altezza dal basso = "Indice di Disugualianza Globale"',
          },
          {
            svg: 'Asset3.svg',
            text_en:
              'Group of lines, height from the bottom = Global Inequality within Countries index',
            text_it:
              'Gruppo di linee, altezza dal basso = "Indice di Disuguaglianza Globale" interno ai paesi',
          },
          {
            svg: 'Asset2.svg',
            text_en:
              'Group of lines, height from the bottom = Global Inequality between Countries index',
            text_it:
              'Gruppo di linee, altezza dal basso = "Indice di Disuguaglianza Globale" tra paesi',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '6.2 The consumption of cigarettes',
          text_it: '6.2 Il consumo di sigarette',
        },
        sections: [
          {
            svg: 'Asset2.svg',
            text_en:
              'Group of lines, height from the bottom = pro capite cigarettes daily sales per continent',
            text_it:
              'Gruppo di linee, altezza dal basso = vendite di sigarette pro capite per continente',
          },
          {
            trigger_overlay: true,
            type: 'cultural',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '6.3 The consumption of alcohol',
          text_it: '6.3 Il consumo di alcol',
        },
        sections: [
          {
            svg: 'Asset4.svg',
            text_en: 'Height of the pattern = pro capite daily alcohol consumption, beer',
            text_it: 'Altezza del pattern = consumo pro capite di alcol, birra',
          },
          {
            svg: 'Asset2.svg',
            text_en: 'Height of the pattern = pro capite daily alcohol consumption, spirits',
            text_it: 'Altezza del pattern = consumo pro capite di alcol, liquori',
          },
          {
            svg: 'Asset3.svg',
            text_en: 'Height of the pattern = pro capite daily alcohol consumption, wine',
            text_it: 'Altezza del pattern = consumo pro capite di alcol, vino',
          },
          {
            svg: 'Asset5.svg',
            text_en: 'Presence of the pattern =  no data due to Prohibitionism',
            text_it: 'Presenza del pattern = mancanza di dati a causa del Proibizionismo',
          },
        ],
      },
    ],
  },
  {
    title: { text_en: '7) Science', text_it: '7) Scienza' },
    data: [
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '7.1 The discovery of chemical elements',
          text_it: '7.1 La scoperta degli elementi chimici',
        },
        sections: [
          {
            svg: 'Asset5.svg',
            text_en: 'Presence of the rectangle = chemical element was discovered that year',
            text_it: "Presenza del rettangolo = un elemento chimico è stato scoperto quell'anno",
          },
          {
            svg: null,
            text_en: 'Color of the rectangle = continent where the discovery happened:',
            text_it: 'Colore del rettangolo = continente dove è avvenuta la scoperta',
            subsections: [
              { svg: 'Asset4.svg', text_en: 'Europa', text_it: 'Europa' },
              { svg: 'Asset3.svg', text_en: 'North America', text_it: 'Nord America' },
              { svg: 'Asset2.svg', text_en: 'Unknown', text_it: 'Sconosciuto' },
            ],
          },
          {
            svg: 'Asset7.svg',
            text_en: 'Number of lines = amounts of people who discovered the element',
            text_it:
              "Numero di linee = numero di persone che hanno contribuito alla scoperta dell'elemento",
          },
          {
            svg: null,
            format: 'italic',
            type: 'sequence',
            text_en: '1-2-3-4 line(s) = 1-2-3-4 people, 5 lines = 5 or more people',
            text_it: '1-2-3-4 linee = 1-2-3-4 persone, 5 linee = 5 o più persone',
          },
          {
            svg: 'Asset8.svg',
            text_en: 'Height of the pattern = atomic number of the element',
            text_it: "Altezza del pattern = numero atomico dell'elemento",
          },
          {
            svg: 'Asset6.svg',
            text_en: 'Position of the white dot = element group, from the top:',
            text_it:
              "Posizione del punto bianco = gruppo di appartenenza dell'elemento, dall'alto:",
          },
          {
            svg: null,
            formatt: 'italic',
            type: 'sequence',
            text_en:
              'transation metal, non-metal, metal, alkali metal, alkali earth metal, noble gas halogen, rare earth',
            text_it:
              'metalli di transizione, non metalli, metalli, metalli alcalini, metalli alcalini terrosi, gas nobili alogeni, terre rare',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '7.2 The discovery and introduction of vaccines and antibiotics',
          text_it: "7.2 La scoperta e l'introduzione di vaccini e antibiotici",
        },
        sections: [
          {
            svg: 'Asset2.svg',
            text_en: 'Presence of the pattern = introduction of a new major antibiotic molecule',
            text_it: 'Presenza del pattern = introduzione di una nuova molecola antibiotica',
          },
          {
            svg: 'Asset3.svg',
            text_en: 'Presence of the pattern = introduction of a new major vaccine type',
            text_it: 'Presenza del pattern = introduzione di un nuovo tipo di vaccino',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '7.3 The explosion of world epidemics',
          text_it: "7.3 L'esplosione delle epidemie nel mondo",
        },
        sections: [
          {
            svg: 'Asset11.svg',
            text_en: 'Presence of the pattern = a recorded epidemic happened in the world',
            text_it: "Presenza del pattern = un'epidemia è stata registrata nel mondo",
            subsections: [
              { svg: 'Asset9.svg', text_en: 'cholera', text_it: 'colera' },
              { svg: 'Asset5.svg', text_en: 'plague', text_it: 'peste' },
              { svg: 'Asset8.svg', text_en: 'dengue fever', text_it: 'dengue' },
              { svg: 'Asset4.svg', text_en: 'smallpox', text_it: 'vaiolo' },
              { svg: 'Asset7.svg', text_en: 'influenza', text_it: 'influenza' },
              { svg: 'Asset3.svg', text_en: 'yellow fever', text_it: 'febbre gialla' },
              { svg: 'Asset6.svg', text_en: 'measles', text_it: 'morbillo' },
              { svg: 'Asset10.svg', text_en: 'other', text_it: 'altro' },
            ],
          },
          {
            svg: 'Asset2.svg',
            text_en: 'Number of lines = death toll of the epidemic, if known',
            text_it: 'Numero di linee = bilancio delle vittime, se noto',
          },
          {
            svg: null,
            type: 'sequence',
            text_en: `1 line = fewer than 10.000 people, 2 lines= fewer than 100.000 people, 
                      3 lines = fewer than 1.000.000 people, 4 lines = fewer than 15.000.000 people, 5 lines = more than 15.000.000 people, No lines = no data`,
            text_it: `1 linea = meno di 10.000 persone, 2 linee = mendo di 100.000 persone, 
                      3 linee = meno di 1.000.000 di persone, 4 linee = meno 15.000.000 di persone, 5 linee = più di 15.000.000 people, assenza di linee = mancanza di dati`,
          },
        ],
      },
    ],
  },

  {
    title: { text_en: '8) Technology', text_it: '8) Tecnologia' },
    data: [
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '8.1 The technological revolution in South Korea',
          text_it: '8.1 La rivoluzione tecnologica in Corea del Sud',
        },
        sections: [
          {
            svg: 'Asset5.svg',
            text_en: 'Rectangle width = South Korean GDP',
            text_it: 'Larghezza del rettangolo = PIL della Corea del Sud',
          },
          {
            svg: 'Asset2.svg',
            text_en: 'Width of the pattern = USD from technological exports in South Korean GDP',
            text_it:
              "Larghezza del pattern = valore in dollari dell'export Coreano relativo alla tecnologia",
          },
          {
            svg: 'Asset4.svg',
            text_en: 'Line, height from the bottom = yearly South Korean import of fish in tonnes',
            text_it: 'Linea, altezza dal basso = import annuo Coreano di pesce in tonnellate',
          },
          {
            svg: 'Asset3.svg',
            text_en: 'Line, height from the bottom = yearly South Korean export of fish in tonnes',
            text_it: 'Linea, altezza dal vasso = export annuo Coreano di pesce in tonnellate',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '8.2 The consumption of fossil fuel',
          text_it: '8.2 Il consumo di combustibili fossili',
        },
        sections: [
          {
            svg: 'Asset4.svg',
            text_en: 'Height of the pattern = yearly global natural gas consumption in TWh',
            text_it: 'Altezza del pattern = consumo annuo globale di gas naturale in TWh ',
          },
          {
            svg: 'Asset3.svg',
            text_en: 'Height of the pattern = yearly global coal consumption in TWh',
            text_it: 'Altezza del pattern = consumo annuo globale di carbone in TWh ',
          },
          {
            svg: 'Asset2.svg',
            text_en: 'Height of the pattern = yearly global crude oil consumption in TWh',
            text_it: 'Altezza del pattern = consumo annuo globale di petrolio greggio in TWh ',
          },
        ],
      },
      {
        subtitle: {
          svg: 'Asset1.svg',
          text_en: '8.3 Access to technology and the connected world',
          text_it: "8.3 L'accesso alla tecnologia e il mondo interconnesso",
        },
        sections: [
          {
            svg: 'Asset8.svg',
            text_en: 'Group of lines, width from the left = per capita electricity consumption',
            text_it:
              'Gruppo di linee, larghezza dalla sinistra = consumo di elettricità pro capite',
          },
          {
            svg: 'Asset7.svg',
            text_en:
              'Group of lines, height from the bottom = global share of indivduals using the internet',
            text_it:
              'Gruppo di linee, altezza dal basso = percentuale globale di individui che utilizzano internet',
          },
          {
            svg: 'Asset6.svg',
            text_en:
              'Group of lines, height from the bottom = global share of individuals having a mobile cellular subscription',
            text_it:
              'Gruppo di linee, altezza dal basso = percentuale globale di individui titolari di un contratto telefonico',
          },
          {
            svg: 'Asset5.svg',
            text_en: 'Presence of the pattern = major event in the history of telecommunications',
            text_it: 'Presenza del pattern = evento rilevante nella storia delle telecomunicazioni',
            subsections: [
              {
                svg: 'Asset4.svg',
                text_en: 'Visual, auditory and ancillary methods (non-electrical)',
                text_it: 'Strumenti visivi, uditivi e accessori (non elettrici) ',
              },
              {
                svg: 'Asset3.svg',
                text_en: 'Basic electric signals',
                text_it: 'Segnali elettrici basilari',
              },

              {
                svg: 'Asset2.svg',
                text_en: 'Advanced electrical and electronical signals',
                text_it: 'Segnali elettrici e elettronici avanzati',
              },
            ],
          },
        ],
      },
    ],
  },
]
