import React from 'react'

export const OverlayCloseCross = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={props.style}>
      <title>48x48</title>
      <rect
        x="-7.59"
        y="22.02"
        width="63.22"
        height="4.01"
        transform="translate(24.02 -9.95) rotate(45)"
      />
      <rect
        x="-7.88"
        y="22.02"
        width="63.81"
        height="4.01"
        transform="translate(57.99 24.02) rotate(135)"
      />
    </svg>
  )
}
