import React from 'react'

export const Fallback = props => (
  <div className={props.className}>
    <div>
      <div className={`b mh3 f4`}>{'THE ROOM OF CHANGE'}</div>
      <div className={'mh3 mv f8'}>{'Sorry! This legend is mobile only!'}</div>
    </div>
  </div>
)
