import React from 'react'
import { inject, observer } from 'mobx-react'
import Section from 'components/Section'
import { Header } from 'components/Header'

import { Footer } from 'components/Footer'
import { Overlay } from 'components/Overlay'
import { Intro } from 'components/Intro'
import { Fallback } from 'components/Fallback'

import { HEADER_HEIGHT } from '../lib/constants'
import { overlayStyle } from '../lib/styles'
import { contents } from '../lib/contents'

@inject('globalState')
@observer
export class Main extends React.Component {
  render() {
    const { mobile, height, overlayOpen, lang } = this.props.globalState

    return (
      <>
        {mobile ? (
          <div style={{ touchAction: overlayOpen ? 'none' : 'auto' }}>
            <Header />
            {/* Fake div to keep the header visible */}
            <div
              style={{
                height: HEADER_HEIGHT,
                padding: '0.5rem',
              }}
            />
            {/* ... */}
            <Overlay style={overlayStyle} viewportHeight={height} id="overlay" />
            <Intro />
            {/* Main body section */}
            <div>
              {contents.map((sectionData, i) => (
                <div key={i} id={`${i}_section`}>
                  <Section index={i} language={`text_${lang}`} sectionData={sectionData} />
                </div>
              ))}
            </div>
            {/* _______________ */}
            <Footer className={'mt6'} />
          </div>
        ) : (
          <Fallback className="ma4" />
        )}
      </>
    )
  }
}
