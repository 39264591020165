import { types as t } from 'mobx-state-tree'
import { debounce } from 'lodash'
import { MOBILE_TRESH } from '../lib/constants'
import { getLanguage } from '../lib/utils'

const FIRST_OCT = 1_569_888_000_000

export const GlobalState = t
  .model('globalState', {
    widthPage: window.innerWidth,
    heightPage: window.innerHeight,
    overlayOpen: false,
    whoClicked: t.maybeNull(t.string),
    lang: t.enumeration(['en', 'it']),
  })
  .actions(self => ({
    computePageSize() {
      self.widthPage = window.innerWidth
      self.heightPage = window.innerHeight
    },
    afterCreate() {
      self.computePageSize()
      window.addEventListener('resize', debounce(self.computePageSize, 50))
    },
    toggleOverlay(whoClicked) {
      const { overlayOpen } = self
      if (overlayOpen) {
        self.overlayOpen = false
      } else {
        self.whoClicked = whoClicked
        self.overlayOpen = true
      }
    },
    closeOverlay() {
      self.overlayOpen = false
    },
    changeLanguage() {
      self.lang = self.lang === 'en' ? 'it' : 'en'
    },
  }))
  .views(self => ({
    get mobile() {
      return self.widthPage < MOBILE_TRESH
    },
    get exhibitionOngoing() {
      return Date.now() < FIRST_OCT
    },
    get textKey() {
      return self.lang === 'en' ? 'text_en' : 'text_it'
    },
  }))

export const globalState = GlobalState.create({ lang: getLanguage() })
