import React from 'react'
import { inject, observer } from 'mobx-react'

@inject('globalState')
@observer
export class Footer extends React.Component {
  render() {
    const { exhibitionOngoing } = this.props.globalState
    const me = exhibitionOngoing ? 'Andrea Titton' : 'Ian Fisherman'
    return (
      <div className={`bt ${this.props.className} gray`}>
        <div className="mt2 ph3 pt2">
          Idea, Research and Design by <span className="b">Accurat</span>
        </div>
        <div className="pa3 i">
          {`Giorgia Lupi (Italian b. 1981), Gabriele Rossi (Italian b. 1981), Stefania Guerra (Italian
          b. 1988), Nicola Guidoboni (Italian b. 1994), Giovanni Magni (Italian b. 1989), Giovanni
          Marchi (Italian b. 1982) Lorenzo Marchionni (Italian b. 1994), ${me} (Italian b. 1996), Alessandro Zotta (Italian b. 1993) of Accurat (Italy, est. 2011).`}
        </div>
      </div>
    )
  }
}
