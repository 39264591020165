import React from 'react'
import { inject, observer } from 'mobx-react'
import { lazyload } from 'react-lazyload'
import { BLOC_SIZE, INDENTED_BLOC_SIZE, WHICH_ONE_TRANSLATION } from '../lib/constants'

const styleOfDescriptionBeforeEqual = {
  color: '#888888',
  fontStyle: 'italic',
}

const FakeBlock = () => (
  <svg width={BLOC_SIZE} height={BLOC_SIZE} style={{ backgroundColor: 'white', flex: 'none' }} />
)

@inject('globalState')
@observer
export class ContinentsOverlayTrigger extends React.Component {
  handleOnClick = () => this.props.globalState.toggleOverlay('cultural')

  render() {
    const { whichOne, globalState } = this.props
    const { lang } = globalState
    const english = lang === 'en'

    const buttonText = english ? 'See the list' : 'Vai alla lista'
    const descriptionTextPre = english ? 'Line colors' : 'Colori linee'
    const whichOneTranslated = english ? whichOne : WHICH_ONE_TRANSLATION[whichOne]
    const descriptionTextPost = english
      ? `continent colors by ${whichOneTranslated} affinity`
      : `colori dei continenti per affinità ${whichOneTranslated}`

    return (
      <>
        <div className="flex flex-row items-center mt2">
          <FakeBlock />

          <div className="ml3 f6">
            <span style={styleOfDescriptionBeforeEqual}>{descriptionTextPre}</span> =<br />{' '}
            {descriptionTextPost}
          </div>
        </div>
        <div className="flex flex-row items-center mt2 mb4">
          <FakeBlock />
          <div className="ml3 bg-white gray pa2 ba b--moon-gray" onClick={this.handleOnClick}>
            {buttonText}
          </div>
        </div>
      </>
    )
  }
}

const SequenceOrList = props =>
  props.isList ? (
    <div className="i ml3 f6">{props.text}</div>
  ) : (
    <div
      className="i ml3 f6"
      dangerouslySetInnerHTML={{
        __html: String(props.text).replace(/[\n\r]/g, '<br/>'),
      }}
    />
  )

const Text = props => {
  const { postEqual, textType, preEqual } = props

  const spanStyle = textType === 'normal' && postEqual ? styleOfDescriptionBeforeEqual : {}
  const equal = postEqual ? '=' : ''

  return (
    <div className={`ml3 f6 mw6 `}>
      {textType === 'normal' ? (
        <>
          <span style={spanStyle}>{preEqual}</span>
          {equal}
          <br /> {postEqual}
        </>
      ) : (
        // <div>foo</div>
        <SequenceOrList isList={textType === 'list'} text={preEqual} />
      )}
    </div>
  )
}

const Image = props => {
  const outerSize = props.small ? INDENTED_BLOC_SIZE : BLOC_SIZE
  return props.isCircle ? (
    <div
      style={{
        width: outerSize,
        height: outerSize,
        alignmentBaseline: 'middle',
        padding: 5,
      }}
      className={props.small ? `small` : 'big'}
    >
      <img
        src={`/assets_sections/${props.blockId}/${props.svg}`}
        style={{
          minWidth: BLOC_SIZE / 2,
          maxWidth: BLOC_SIZE / 2,
          minHeight: BLOC_SIZE / 2,
          maxHeight: BLOC_SIZE / 2,
          display: 'block',
          margin: 'auto',
        }}
      />
    </div>
  ) : (
    <img
      src={`/assets_sections/${props.blockId}/${props.svg}`}
      style={{ width: outerSize, height: outerSize, flex: 'none' }}
    />
  )
}

const SVGorFallback = props => (props.svg ? <Image {...props} /> : <FakeBlock />)

const SubsectionBlockInstance = props => {
  const { text, svg, blockId, type } = props
  const isCircle = type === 'circle'
  return (
    <div className="flex flex-row items-center mt2 mb3">
      <SVGorFallback svg={svg} blockId={blockId} isCircle={isCircle} small={true} />
      <div className="ml3 f6">
        <span style={styleOfDescriptionBeforeEqual} /> {text}
      </div>
    </div>
  )
}

const StandardBlockInstance = props => {
  const { text, svg, blockId, subsections, language, type, triggerOverlay } = props

  const [preEqual, postEqual] = text ? text.split('=') : [null, null]

  const isCircle = type === 'circle'

  return !triggerOverlay ? (
    <>
      <div className="flex flex-row items-center mt2 mb3">
        {/* The instance image, if it is null fallsback */}
        <SVGorFallback svg={svg} blockId={blockId} isCircle={isCircle} small={false} />
        {/* The instance description */}
        <Text
          preEqual={type === 'sequence' ? text : preEqual}
          postEqual={!(type === 'sequence') && postEqual}
          textType={['sequence', 'list'].includes(type) ? type : 'normal'}
        />
      </div>
      {/* If there is a subsection in list format */}
      {subsections && (
        <div className="ml4">
          {subsections.map((sub, j) => (
            <SubsectionBlockInstance
              key={`${sub[language]}_${j}`}
              text={sub[language]}
              svg={sub.svg}
              type={sub.type}
              blockId={blockId}
            />
          ))}
        </div>
      )}
    </>
  ) : (
    <ContinentsOverlayTrigger whichOne={type} />
  )
}

@lazyload({
  once: true,
  height: 200,
  offset: 100,
})
export default class Section extends React.Component {
  render() {
    const { sectionData, index, language } = this.props
    const { title, data } = sectionData

    const tranlsatedTitle = title[language]

    return (
      <div className="mv5">
        <h3 className="ph2">{tranlsatedTitle}</h3>
        {data.map((oneInstanceDownData, n) => {
          const blockId = `${index + 1}.${n + 1}`
          const { subtitle, sections } = oneInstanceDownData

          return (
            <React.Fragment key={`${subtitle['text_en']}_${n}`}>
              <div className="w-100 mt2 ph2 mb3">{subtitle[language]}</div>
              <img src={`/assets_sections/${blockId}/${subtitle.svg}`} />
              <div className="ma3 f6 mb5">
                {sections.map((instanceOfSectionData, m) => {
                  const {
                    svg,
                    subsections,
                    type,
                    trigger_overlay: triggerOverlay,
                  } = instanceOfSectionData

                  const text = instanceOfSectionData[language]

                  return (
                    <StandardBlockInstance
                      key={`${text}_${n}_${m}`}
                      text={text}
                      language={language}
                      svg={svg}
                      subsections={subsections}
                      type={type}
                      triggerOverlay={triggerOverlay}
                      blockId={blockId}
                    />
                  )
                })}
              </div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}
