import React from 'react'
import { inject, observer } from 'mobx-react'

import { SvgIntroHorizontal } from 'components/assets/IntroHorizontal'
import { SvgIntroHorizontalItalian } from 'components/assets/IntroHorizontalItalian'
import { CulturalLegend } from 'components/Overlay'
import { DiscoverButton } from './Header'
import {
  FULL_TEXT_FIRST,
  SMALL_TEXT,
  FULL_TEXT_SECOND,
  SECOND_DESCRIPTION,
  UNDER_WALL_DESCRIPTION,
  INDENTED_BLOC_SIZE,
} from '../lib/constants'

const LanguageSelection = props => (
  <div onClick={props.changeLanguage} className={`ma4 gray ${props.className || ''}`}>
    {props.currentLanguage === 'en' ? (
      <>
        {'Vai alla '}
        <span className="fw7">{'versione italiana'}</span>
      </>
    ) : (
      <>
        {'Switch to the '}
        <span className="fw7">{'english version'}</span>
      </>
    )}
  </div>
)

export const Legend = props => {
  const rapresentableContinents = props.continents[props.textKey]
  const englishContinents = props.continents['text_en']
  const cult =
    props.name === 'by geographical affinity' || props.name === 'per affinità geografica'
      ? 'Geo'
      : 'Cultural'

  return (
    <div className={props.className}>
      <div className="b mb3">{props.name}</div>
      <div className="flex flex-column flex-wrap" style={{ width: '100%', height: 150 }}>
        {rapresentableContinents.map((cont, index) => {
          const svgContName = englishContinents[index]
          const svgName = `${svgContName.replace(/\s/g, '')}${cult}`

          return (
            <div key={cont} className="flex flex-row  mv2">
              <img
                src={`legend_continents/${svgName}.svg`}
                className="mt1"
                style={{ width: INDENTED_BLOC_SIZE, height: INDENTED_BLOC_SIZE }}
              />
              <div className="fitems-center mh3 mw4">{cont}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

@inject('globalState')
@observer
export class Intro extends React.Component {
  state = { fullText: false }

  handleOnClick = () => this.setState({ fullText: !this.state.fullText })
  handleOverlayToggle = () => this.props.globalState.toggleOverlay('contents')

  render() {
    const { fullText } = this.state
    const { lang, changeLanguage, textKey } = this.props.globalState

    const keepReading = lang === 'en' ? 'Keep reading' : 'Continua a leggere'
    const showLess = lang === 'en' ? 'Show less' : 'Mostra meno'

    return (
      <div>
        <LanguageSelection currentLanguage={lang} changeLanguage={changeLanguage} />
        <div className="mh4 mt4 mb2">
          <div className="b mv3 f3">{'THE ROOM OF CHANGE'}</div>
          <div>
            {!fullText ? (
              <div>{SMALL_TEXT[textKey]}</div>
            ) : (
              <div>
                {FULL_TEXT_FIRST[textKey]} <br /> {FULL_TEXT_SECOND[textKey]}
              </div>
            )}
          </div>
          <span className="b" onClick={this.handleOnClick}>
            {!fullText ? keepReading : showLess}
          </span>
        </div>
        <div className="pointer" onClick={this.handleOverlayToggle}>
          <DiscoverButton className="mh4 mv4 pa3 tc ba b--moon-gray" lang={lang} />
        </div>
        <div className="mh4 mt5 mb4">
          <div className="b mv3 f4">
            {lang === 'en' ? 'How to interpret it' : 'Come interpretarlo'}
          </div>
          <div>{SECOND_DESCRIPTION[textKey]}</div>
        </div>

        <div className="overflow-x-auto mv5">
          {lang === 'en' ? (
            <SvgIntroHorizontal style={{ width: 900 }} />
          ) : (
            <SvgIntroHorizontalItalian style={{ width: 900 }} />
          )}
        </div>
        <div className="mh4 mb4">
          <div>{UNDER_WALL_DESCRIPTION[textKey]}</div>
        </div>
        <CulturalLegend displayText={false} />
      </div>
    )
  }
}
