export const BLOC_SIZE = 20
export const INDENTED_BLOC_SIZE = 15

export const DIMENSIONS = {
  HEIGHT_LEGEND: 200,
  PANEL_HEIGHT: 250,
  MARGIN_VERTICAL: 8,
}

export const SECTION_TITLE_HEIGHT = DIMENSIONS.HEIGHT_LEGEND / 3
export const MOBILE_TRESH = 1000
export const HEADER_HEIGHT = 64

export const SMALL_TEXT = {
  text_en:
    'The Room of Change installation is a hand-crafted data-tapestry illustrating how multiple aspects of our environment have changed in the past centuries, how they are still changing, and how they will likely continue changing.',
  text_it:
    'L’installazione “The Room of Change” è un arazzo di dati realizzato a mano, che illustra come i molteplici aspetti del nostro ambiente siano cambiati nei secoli passati, come questi stiano continuando a cambiare e come probabilmente cambieranno in futuro.',
}
export const FULL_TEXT_FIRST = {
  text_en:
    'The Room of Change installation is a hand-crafted data-tapestry illustrating how multiple aspects of our environment have changed in the past centuries, how they are still changing, and how they will likely continue changing. Combining several different data sources depicting the world from both global and local-individual perspectives, the installation tells stories of people and their relationship with what has been around them over time, layering dense and granular information within the narration to highlight how change is pervasive at all scales.',
  text_it:
    'L’installazione “The Room of Change” è un arazzo di dati realizzato a mano, che illustra come i molteplici aspetti del nostro ambiente siano cambiati nei secoli passati, come questi stiano continuando a cambiare e come probabilmente cambieranno in futuro.',
}
export const FULL_TEXT_SECOND = {
  text_en:
    'The piece revolves around eight macro topics that organize information, all related to humans, but that reveal consequences that oftentimes affect us and other species simultaneously. They are illustrated through a number of global data sets to frame large-scale phenomena with broad strokes as well as single and specific stories that directly or indirectly represent the micro consequences of the large-scale phenomena.',
  text_it:
    'Attingendo informazioni da varie fonti che descrivono il mondo da prospettive sia globali che locali-individuali, l’installazione racconta la storia dell’uomo e delle sue relazioni con quanto è successo attorno a lui nello scorrere del tempo, sovrapponendo informazioni granulari e dettagliate all’interno della narrazione per evidenziare come il cambiamento sia pervasivo su tutte le scale. Il lavoro ruota attorno alla scelta di otto macro-temi che organizzano le informazioni: sono tutti riguardanti gli umani, ma rivelano conseguenze che talvolta coinvolgono anche altre specie. Questi macro-temi, presentati attraverso una serie di dataset globali, inquadrano sia fenomeni su larga scala che storie singole e specifiche che rappresentano direttamente o indirettamente le micro conseguenze degli avvenimenti più generali.',
}
export const SECOND_DESCRIPTION = {
  text_en:
    'Each horizontal stripe depicts the evolution over time of one single story of change, told through a visualization of a combination of datasets on the topic.',
  text_it:
    'Ogni linea orizzontale rappresenta l’evoluzione nel tempo di una singola storia del cambiamento raccontata attraverso la visualizzazione della combinazione dei dataset relativi al macro-tema di riferimento.',
}
export const UNDER_WALL_DESCRIPTION = {
  text_en:
    'In the source datasets represented in the tapestry, data on continents is sometimes grouped by geographical affinity and other times by cultural affinity. These two alternative color scales are used to depict the two different categorizations.',
  text_it:
    'Nei dataset rappresentati, le informazioni suddivise per continente sono state raggruppate per affinità geografica e per affinità culturale. Le seguenti scale cromatiche sono state utilizzate per rappresentare le due differenti categorizzazioni.',
}
export const CONTINENTS_1 = {
  text_en: [
    'Africa',
    'Oceania',
    'Asia',
    'Europe',
    'Latin America',
    'North America',
    'Northern Africa and Middle East',
  ],
  text_it: [
    'Africa',
    'Oceania',
    'Asia',
    'Europa',
    'America Latina',
    'Nord America',
    'Nord Africa e Medio Oriente',
  ],
}

export const CONTINENTS_2 = {
  text_en: ['Africa', 'Oceania', 'Asia', 'Europe', 'North America', 'South America'],
  text_it: ['Africa', 'Oceania', 'Asia', 'Europa', 'Nord America', 'Sud America'],
}

export const WHICH_ONE_TRANSLATION = {
  cultural: 'culturale',
  geographical: 'geografica',
}

export const BIG_PHONE_WIDHT = 321
