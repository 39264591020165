export const overlayStyle = {
  position: 'fixed',
  width: '100vw',
  height: '100%',
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255,255,255,1)',
  cursor: 'pointer',
  zIndex: 2,
}

export const headerDefaultStyle = {
  borderBottomStyle: 'solid',
  borderWidth: 1,
  borderColor: 'silver',
  padding: '0.5rem',
  position: 'fixed',
  top: 0,
  width: '100vw',
  zIndex: 2,
  backgroundColor: 'white',
}
