import React from 'react'
import Hammer from 'hammerjs'
import { inject, observer } from 'mobx-react'

import { Legend } from 'components/Intro'
import { HEADER_HEIGHT, CONTINENTS_1, CONTINENTS_2 } from '../lib/constants'
import { contents } from '../lib/contents'
import { scrollAfterHeader, noop } from '../lib/utils'

@inject('globalState')
@observer
export class CulturalLegend extends React.Component {
  handleOnClick = () => {
    this.props.globalState.toggleOverlay('cultural')
  }

  render() {
    const { toggleOverlay, textKey, lang, overlayOpen } = this.props.globalState
    return (
      <div onClick={toggleOverlay && overlayOpen ? this.handleOnClick : noop} className="h-100">
        {this.props.displayText && (
          <div className="mh4 mt3 mb4">
            <div className="b f4">{lang === 'en' ? 'Continent Colors' : 'Colori Continenti'}</div>
          </div>
        )}
        <Legend
          className={'mh4 mv3 mb5'}
          name={lang === 'en' ? 'by cultural affinity' : 'per affinità culturale'}
          continents={CONTINENTS_1}
          textKey={textKey}
        />
        <Legend
          className={'mh4 mv3 mb5'}
          name={lang === 'en' ? 'by geographical affinity' : 'per affinità geografica'}
          continents={CONTINENTS_2}
          textKey={textKey}
        />
      </div>
    )
  }
}

const Contents = props => {
  return contents.map((sect, i) => (
    <div
      key={i}
      onClick={props.onClickFactory(i)}
      style={{
        height: props.sectionHeight,
        borderWidth: 2,
      }}
      className={`flex flex-row items-center pointer bt w100 b--black`}
    >
      <img
        src={`overlay_graphs_150/t150_tripletta_overlay-0${i + 1}.png`}
        style={{ height: '100%' }}
      />
      <div className="ml3 b">{sect.title[props.textKey]}</div>
    </div>
  ))
}
@inject('globalState')
@observer
export class Overlay extends React.Component {
  onClickFactory = index => () => {
    const { whoClicked, toggleOverlay } = this.props.globalState
    const id = `${index}_section`
    const element = document.getElementById(id)
    if (element) {
      scrollAfterHeader(element)
    }
    toggleOverlay(whoClicked)
  }

  handleSwipeUp = () => {
    const { closeOverlay } = this.props.globalState
    closeOverlay()
  }

  componentDidMount() {
    const overlay = document.getElementById('overlay')
    const mc = Hammer(overlay)
    mc.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL })
    mc.on('swipeup', this.handleSwipeUp)
    this.mc = mc
  }

  render() {
    const { style, globalState } = this.props
    const { overlayOpen, whoClicked, heightPage, textKey } = globalState

    const sectionHeight = (heightPage - HEADER_HEIGHT) / contents.length
    const renderContents = whoClicked === 'contents'

    return (
      <div
        style={{
          ...style,
          top: HEADER_HEIGHT,
          height: '100%',
          transform: !overlayOpen ? 'translate3d(0, -120%, 0)' : 'translate3d(0,0,0)',
          transition: '450ms cubic-bezier(0.785, 0.135, 0.15, 0.86)',
        }}
        id={'overlay'}
        className="items-baseline"
      >
        {!renderContents ? (
          <CulturalLegend toggleOverlay={this.togglePanel} displayText={true} textKey={textKey} />
        ) : (
          <Contents
            sectionHeight={sectionHeight}
            onClickFactory={this.onClickFactory}
            textKey={textKey}
          />
        )}
      </div>
    )
  }
}
