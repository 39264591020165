import { DIMENSIONS, HEADER_HEIGHT, SECTION_TITLE_HEIGHT } from './constants'
const { HEIGHT_LEGEND, MARGIN_VERTICAL } = DIMENSIONS

export const computeScrollableHeight = nItems => {
  return (HEIGHT_LEGEND * 3 + SECTION_TITLE_HEIGHT + MARGIN_VERTICAL) * nItems - MARGIN_VERTICAL
}

export const scrollAfterHeader = element => {
  element.scrollIntoView(true)
  const distanceScrolled = window.scrollY
  if (distanceScrolled) {
    window.scroll(0, distanceScrolled - HEADER_HEIGHT - 20)
  }
}
export const noop = () => null

export function getLanguage() {
  const lang = window.navigator.userLanguage || window.navigator.language
  const code = lang ? lang.split('-')[0] : 'en'

  return code === 'it' ? 'it' : 'en'
}
